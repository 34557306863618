// src/features/Plan/lostPlanSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchLostPlan = createAsyncThunk(
  "plan/lostPlan",
  async ({ page = 1, limit = 10, search }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        "/fetch-briefs-lost-plans-list/admin-appsells",
        {
          params: { page, limit, search },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch lost plans");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching lost plans"
      );
    }
  }
);

// Fetch In progress Plan 
export const fetchInProgressPlan = createAsyncThunk(
  "plan/InProgressPlan",
  async ({ page = 1, limit = 10, search }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        "/fetch-briefs-inprogress-plans-list/admin-appsells",
        {
          params: { page, limit, search },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch plans in progress");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching plans in progress"
      );
    }
  }
);
export const fetchLostPlanByBriefID = createAsyncThunk(
  "plan/lostPlanById",
  async ({ brief_id, page, limit }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        `/fetch-lost-plans-admin/${brief_id}`,
        {
          params: { page, limit },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch lost plans");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching lost plans"
      );
    }
  }
);

const lostPlanSlice = createSlice({
  name: "planLost",
  initialState: {
    items: [],
    totalItems: 0,
    status: "idle",
    error: null,
    loading: false,
    lostPlansById: [],
    totalPlansAll:0 ,
    reason: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLostPlan.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchLostPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload.plans; // assuming the API returns an object with a 'plans' key
        state.totalItems = action.payload.totalPlans; // assuming the API returns the total number of items
        state.totalPlansAll = action.payload.allTotal ;
        state.loading = false;
      })
      .addCase(fetchLostPlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })
      // For plans in progress 
      .addCase(fetchInProgressPlan.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchInProgressPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload.plans; // assuming the API returns an object with a 'plans' key
        state.totalItems = action.payload.totalPlans; // assuming the API returns the total number of items
        state.totalPlansAll = action.payload.allTotal ;
        state.loading = false;
      })
      .addCase(fetchInProgressPlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchLostPlanByBriefID.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchLostPlanByBriefID.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.lostPlansById = action.payload.plans;
        state.totalItems = action.payload.total;
        state.loading = false;
        state.reason = action.payload.plans.reason;

      })
      .addCase(fetchLostPlanByBriefID.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default lostPlanSlice.reducer;
