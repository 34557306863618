// src/features/BriefSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../httpService/httpService";

export const deleteBrief = createAsyncThunk(
  "brief/delete",
  async (brief_id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.delete(
        `/delete-brief-admin-appsells/${brief_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data) {
        return rejectWithValue("Failed to delete Brief");
      }

      return brief_id ; // Returning BriefId to identify which Brief was deleted
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred during the deletion";
      return rejectWithValue(message);
    }
  }
);

const deleteBriefSlice = createSlice({
  name: "Brief",
  initialState: {
    status: null,
    error: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteBrief.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(deleteBrief.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
      })
      .addCase(deleteBrief.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default deleteBriefSlice.reducer;
