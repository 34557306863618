import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchBriefCount = createAsyncThunk(
  "fetchBriefCount",
  async (_,{ rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        "/fetch-count-brief-status-wise",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch brief counts");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching brief counts"
      );
    }
  }
);

const fetchBriefCountSlice = createSlice({
  name: "fetchBriefCount",
  initialState: {
    newCount: 0,
    rejectedCount: 0,
    approvedCount: 0,
    status: "idle",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBriefCount.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchBriefCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log(
          "inside succeed payload : " + JSON.stringify(action.payload)
        );
        state.newCount = action.payload.pendingBriefsCount;
        state.rejectedCount = action.payload.rejectedBriefsCount;
        state.approvedCount = action.payload.approvedBriefsCount;
        state.loading = false;
      })
      .addCase(fetchBriefCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default fetchBriefCountSlice.reducer;
