import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchPendingBrief = createAsyncThunk(
  "pendingBrief/fetchPendingBrief",
  async ({ page = 1, limit = 10, search }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        "/fetch-all-approved-briefs-admin",
        {
          params: {
            page,
            limit,
            search,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch pending briefs");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching pending briefs"
      );
    }
  }
);

const fetchPendingBriefSlice = createSlice({
  name: "pendingBrief",
  initialState: {
    items: [],
    totalItems: 0,
    status: "idle",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPendingBrief.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchPendingBrief.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload.briefs;
        state.totalItems = action.payload.total;
        state.loading = false;
      })
      .addCase(fetchPendingBrief.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default fetchPendingBriefSlice.reducer;
