// src/productsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchInProgressPlan = createAsyncThunk(
    "plan/InProgressPlan",
    async ({ page = 1, limit = 10, search }, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axiosInstance.get(
          "/fetch-briefs-inprogress-plans-list/admin-appsells",
          {
            params: { page, limit, search },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (!response.data || response.status !== 200) {
          throw new Error("Failed to fetch plans in progress");
        }
  
        return response.data;
      } catch (error) {
        return rejectWithValue(
          error.message || "An error occurred while fetching plans in progress"
        );
      }
    }
  );

const InProgressPlanSlice = createSlice({
  name: "InProgressPlan",
  initialState: {
    items: [],
    totalItems: 0,
    status: "idle",
    error: null,
    loading: false,
    totalPlansAll:0 ,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchInProgressPlan.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchInProgressPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload.plans; // assuming the API returns an object with a 'plans' key
        state.totalItems = action.payload.totalPlans; // assuming the API returns the total number of items
        state.totalPlansAll = action.payload.allTotal ;
        state.loading = false;
      })
      .addCase(fetchInProgressPlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })
  },
});

export default InProgressPlanSlice.reducer;
