// src/productsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchPlan = createAsyncThunk(
  "plan/fetchPlan",
  async ({plan_id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      console.log(" inside fetchPlan plan_id : " + plan_id) ;
      const response = await axiosInstance.get(
        `/fetch-plan/admin-appsells/${plan_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch plans");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching plans"
      );
    }
  }
);

const fetchPlanSlice = createSlice({
  name: "fetchPlan",
  initialState: {
    plan: {},
    status: "idle",
    error: null,
    loading: false,
    headers :[] ,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlan.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchPlan.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.plan = action.payload.plan;
        // console.log("action.payload. : " + JSON.stringify(action.payload)) ;
        state.headers = action.payload.dynamicHeaders ;
        
        state.loading = false;
      })
      .addCase(fetchPlan.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default fetchPlanSlice.reducer;
