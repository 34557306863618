import React from "react";
import { Table, Typography } from "antd";
import "../ConsolidatedTab/ConsolidatedSummary.css";
import { Widgets } from "@mui/icons-material";

const { Title } = Typography;
const BreakUpTable = ({ apiResponse, title }) => {
  console.log("reponse is------------------: " + JSON.stringify(apiResponse));

  const formatNumber = (num) => {
    if (num === undefined || num === null) {
      return "-"; // Return a placeholder for undefined or null values
    }

    num = Number(num);

    const roundedNum = Math.round(num * 100) / 100; // Round to 2 decimal places

    if (roundedNum >= 1e12) {
      return (roundedNum / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
    } else if (roundedNum >= 1e9) {
      return (roundedNum / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    } else if (roundedNum >= 1e6) {
      return (roundedNum / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    } else if (roundedNum >= 1e3) {
      return (roundedNum / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    } else {
      return roundedNum.toString(); // Less than 1000
    }
  };

  const summaryColumns = [
    {
      title: "Conventional",
      dataIndex: "conventional",
      key: "conventional",
      className: "left-align",
       width:'10%' ,
      render: (text) => <div>{text}</div>, // Render the conventional text
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      width : '10%' ,
      render: (text) => <div>{text}</div>, // Render the quantity
    },
    {
      title: "SQ.ft",
      dataIndex: "square_feet",
      key: "square_feet",
      width :'10%' ,
      render: (text) => <div>{text}</div>, // Render the square feet
    },
  ];

  // Transforming data for the table
  const tableData = [
    {
      key: "1", // Unique key for FOC row
      conventional: "FOC",
      quantity: apiResponse.plan.sites[0].foc_count,
      square_feet: apiResponse.plan.sites[0].foc_total_sqft,
    },
    {
      key: "2", // Unique key for PAID row
      conventional: "PAID",
      quantity: apiResponse.plan.sites[0].paid_count,
      square_feet: apiResponse.plan.sites[0].paid_total_sqft,
    },
    {
      key: "total", // Unique key for PAID row
      conventional: "Total",
      quantity: apiResponse.plan.sites[0].total_count,
      square_feet: apiResponse.plan.sites[0].total_sqft,
    },
  ];

  return (
    <div className="table-wrapper-3">
      <Title level={4} style={{textAlign:'center' }} className="table-heading" >{title}</Title>
      <Table
        className="summary-table"
       
        columns={summaryColumns}
        dataSource={tableData}
        pagination={false}
        rowClassName={(record) => (record.key === "total" ? "total-row" : "")}
        bordered
      />
    </div>
  );
};
export default BreakUpTable;
