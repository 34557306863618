import React, { useEffect } from "react";
import "../ConsolidatedTab/consolidatedStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchFinancialsSummary } from "../../../../../features/PlanSummary/fetchFinancialsSummarySlice";
import { fetchFinancialGraph } from "../../../../../features/PlanSummary/fetchFinancialGraphSlice";
import Tables from "./Tables";
import CustomPieChart from "../Charts/FOC_Graph";

export default function FinancialsTabContent({conventional_plan ,digital_plan , data}) {
  const dispatch = useDispatch();
  // const { data } = useSelector((state) => state.fetchFinancialsSummary);
  // const {conventional_plan ,digital_plan} = useSelector((state) => state.fetchFinancialGraph );
  const { plan_id } = useParams();

  // useEffect(() => {
  //   dispatch(fetchFinancialsSummary(plan_id));
  //   dispatch(fetchFinancialGraph(plan_id));

  // }, []);
  // useEffect(() => {
  //   dispatch(fetchFinancialsSummary(plan_id));
  //   dispatch(fetchFinancialGraph(plan_id));

  // }, [dispatch, plan_id]);

 
   
// console.log("data is ; "  + JSON.stringify(data)) ;

  const percentages = {
    foc_percentage: conventional_plan?.percentage,
    paid_percentage: digital_plan?.percentage,
  };
  console.log("percentages : " + JSON.stringify(percentages));
  const colors = ["#FFA500", "#8CCB7E"]; // Define any colors you need
  const labels = ["Conventional", "Digital"]; // Define your labels

  const loading = useSelector((state) => state.fetchFinancialsSummary.loading);
return (
  <div className="consolidated-tab-container">
    {loading ? (
      <div>Loading...</div>
    ) : data && percentages ? (
      <div style={{ display: "flex", gap: "5%" }}>
        <Tables data={data} />
        <CustomPieChart percentages={percentages} colors={colors} labels={labels} />
      </div>
    ) : (
      <div>No data available.</div>
    )}
  </div>
);

//   return (
//     <div className="consolidated-tab-container">
//       {data ? (
//         <div style={{ display: "flex", gap: "5%" }}>
//           <Tables data={data}/>
//           <CustomPieChart percentages={percentages} colors={colors} labels={labels} />
//         </div>
//       ) : (
//         <div>No data available.</div>
//       )}
//     </div>
//   );
}
