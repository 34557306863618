import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import signupReducer from "./features/signup/signupSlice";
import clientManagementSlice from "./features/ClientReducer/clientManagementSlice";

import signInSlice from "./features/SignIn/signInSlice";
import createPassworsSlice from "./features/CreatePassword/createPassworsSlice";
import clientSlice from "./features/clientSlice";
import deleteClientSlice from "./features/deleteClientSlice";
import updateClientSlice from "./features/updateClientSlice";
import getUserSlice from "./features/getUserSlice";
import createUserSlice from "./features/createUserSlice";
import getRolesSlice from "./features/getRolesSlice";
import deleteUserSlice from "./features/deleteUserSlice";
import editUserSlice from "./features/editUserSlice";
import fetchAdminMediumsSlice from "./features/fetchAdminMediumsSlice";
import editAdminMediumSlice from "./features/editAdminMediumSlice";
import addMediumSlice from "./features/AdminMediums/addMediumSlice";
import deleteAdminMediumSlice from "./features/AdminMediums/deleteAdminMediumSlice";
import fetchAdminSECSlice from "./features/AdminSEC/fetchAdminSECSlice";
import addAdminSECSlice from "./features/AdminSEC/addAdminSECSlice";
import fetchAdminCitiesSlice from "./features/Cities/fetchAdminCitiesSlice";
import editAdminSecSlice from "./features/AdminSEC/editAdminSecSlice";
import deleteSECSlice from "./features/AdminSEC/deleteSECSlice";
import editAdminCitySlice from "./features/Cities/editAdminCitySlice";
import addAdminCitySlice from "./features/Cities/addAdminCitySlice";
import deleteAdminCitySlice from "./features/Cities/deleteAdminCitySlice";

import fetchPendingBriefSlice from "./features/fetchPendingBrief/fetchPendingBriefSlice";

import fetchApprovedBriefSlice from "./features/fetchApprovedBreif/fetchApprovedBriefSlice";
import fetchRejectedBriefSlice from "./features/fetchRejectedBrief/fetchRejectedBriefSlice";
import fetchClientBriefSlice from "./features/fetchApprovedBreif/fetchNewBriefByClientId";
import recordBriefSlice from "./features/recordBriefSlice";

import acceptBriefSlice from "./features/acceptBrief/acceptBriefSlice";

import rejectBriefSlice from "./features/rejectBreif/rejectBriefSlice";
import deleteBriefSlice from "./features/deleteBriefSlice.jsx";
import approvedPlanSlice from "./features/Plan/approvedPlanSlice";
import fetchPlanSlice from "./features/Plan/fetchPlanSlice.js";
import InProgressPlanSlice from "./features/Plan/InProgressPlanSlice.js";
import recordPlainSlice from "./features/Plan/recordPlainSlice";

import lostPlainSlice from "./features/Plan/lostPlainSlice";

import fetchPOSlice from "./features/Plan/fetchPOSlice";

import approvedPOSlice from "./features/Plan/approvedPOSlice";

import fetchCampaignSlice from "./features/Campaign/fetchCampaignSlice";

import selectedCampaignSlice from "./features/Campaign/selectedCampaignSlice";

import rejectPOSlice from "./features/Plan/rejectPOSlice";

import singleBriefSlice from "./features/singleBriefSlice";

import fetchRegionsSlice from "./features/AddSite/fetchRegionsSlice";
import alterPlanSlice from "./features/Plan/alterPlanSlice";
import siteManagementSlice from "./features/siteManagementSlice";
import modificationRequiredSlice from "./features/Plan/modificationRequiredSlice.js";
import modificationPendingSlice from "./features/Plan/modificationPendingSlice.js";
import campaignSlice from "./features/CampaignSlice/campaignSlice";
import OperationAnalystSlice from "./features/OperationAnalystSlice";
import fetchClientCampaignSlice from "./features/Campaign/fetchClientCampaignSlice";
import dashboardSlice from "./features/dashboard/dashboardSlice";
import createPlanSlice from "./features/Plan/createPlanSlice.js";
import tabSlice from "./features/tabSlice";
import fetchAnalysisDataSlice from "./features/PreCampaignAnalysis/fetchAnalysisData.jsx";

// Plan Summary
import fetchConsolidateSummarySlice from "./features/PlanSummary/fetchconsolidatedSummarySlice.js";
import fetchDigitalSummarySlice from "./features/PlanSummary/fetchDigitalSummarySlice.js";
import  fetchConventionalSummarySlice from "./features/PlanSummary/fetchConventionalSummarySlice.js";
import  fetchBreakUpSummarySlice from "./features/PlanSummary/fetchBreakUpSummarySlice.js";
import  fetchFinancialsSummarySlice  from "./features/PlanSummary/fetchFinancialsSummarySlice.js";
import  fetchFinancialGraphSlice  from "./features/PlanSummary/fetchFinancialGraphSlice.js";
import  fetchMapDataSlice, { fetchMapData }  from "./features/geographicalSpread/fetchmapdataSlice.js";

//  Brief counts 
import  fetchBriefCountSlice  from "./features/fetchBriefCount/fetchBriefCountSlice.js";
import  fetchPlanCountSlice  from "./features/Plan/fetchPlanCountSlice.js";

//  For plan name
 import planNameSlice from "./features/Plan/setPlanNameSlice.js";
//  Forget password 
import forgetPasswordSlice  from "./features/forgetPasswordSlice.js";
const rootReducer = combineReducers({
  signup: signupReducer,
  clientMangement: clientManagementSlice,
  signIn: signInSlice,
  createPassword: createPassworsSlice,
  createClient: clientSlice,
  deleteClient: deleteClientSlice,
  updateClient: updateClientSlice,
  getUser: getUserSlice,
  createUser: createUserSlice,
  getRoles: getRolesSlice,
  deleteUser: deleteUserSlice,
  editUser: editUserSlice,
  adminMedium: fetchAdminMediumsSlice,
  editMedium: editAdminMediumSlice,
  addMedium: addMediumSlice,
  deleteMedium: deleteAdminMediumSlice,
  fetchCity: fetchAdminCitiesSlice,
  editCity: editAdminCitySlice,
  addCity: addAdminCitySlice,
  deleteCity: deleteAdminCitySlice,
  fetchSEC: fetchAdminSECSlice,
  addSEC: addAdminSECSlice,
  editSec: editAdminSecSlice,
  deleteSec: deleteSECSlice,
  pendingBreif: fetchPendingBriefSlice,
  approvedBrief: fetchApprovedBriefSlice,
  rejectedBrief: fetchRejectedBriefSlice,
  clientBrief: fetchClientBriefSlice,
  selectedBrief: recordBriefSlice,
  acceptBrief: acceptBriefSlice,
  rejectBrief: rejectBriefSlice,
  approvedPlan: approvedPlanSlice,
  fetchPlan: fetchPlanSlice,
  selectedPlan: recordPlainSlice,
  InProgressPlan: InProgressPlanSlice,
  lostPlan: lostPlainSlice,
  fetchPO: fetchPOSlice,
  approvedPO: approvedPOSlice,
  fetchCampaign: fetchCampaignSlice,
  selectedCampaign: selectedCampaignSlice,
  rejectPO: rejectPOSlice,
  singleBrief: singleBriefSlice,
  fetchRegions: fetchRegionsSlice,
  alterPlan: alterPlanSlice,
  siteManagement: siteManagementSlice,
  campaignDetail: campaignSlice,
  OperationAnalyst: OperationAnalystSlice,
  dashboard: dashboardSlice,
  currentTab: tabSlice,
  clientCampaign: fetchClientCampaignSlice,
  createPlan: createPlanSlice,
  fetchAlterRequired: modificationRequiredSlice,
  fetchAlterPending: modificationPendingSlice,
  fetchAnalysisData: fetchAnalysisDataSlice,
  fetchConsolidateSummary: fetchConsolidateSummarySlice,
  fetchDigitalSummary: fetchDigitalSummarySlice,
  fetchConventionalSummary : fetchConventionalSummarySlice ,
  fetchBreakUpSummary : fetchBreakUpSummarySlice ,
  fetchFinancialsSummary : fetchFinancialsSummarySlice ,
  fetchFinancialGraph: fetchFinancialGraphSlice ,
  fetchMapData :  fetchMapDataSlice,
  fetchBriefCount : fetchBriefCountSlice ,
  fetchPlanCount  :fetchPlanCountSlice ,
  deleteBrief : deleteBriefSlice ,
  plan_name : planNameSlice ,
  forgotPassword : forgetPasswordSlice ,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["selectedBrief", "selectedPlan", "fetchPO", "selectedCampaign"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
