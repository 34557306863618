import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../httpService/httpService";

const initialState = {
  getSiteStatusesApi: {},
  updateExecutionStatusApi: {},
};

// Async thunk for getting sites statuses
export const getSitesStatuses = createAsyncThunk(
  "operationAnalyst/getStatuses",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.get(
        `/admin/fetch-execution-statuses-dropdown?site_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch clients");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching clients"
      );
    }
  }
);

// Async thunk for update execution status id tracker to sites
export const updateExecutionStatus = createAsyncThunk(
  "operationAnalyst/updateExecutionId",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.post(
        `/admin/update-execution-status?execution_status_id=${data?.execution_status_id}&site_id=${data?.site_id}&plan_id=${data?.plan_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data) {
        return rejectWithValue("Failed to update status");
      }

      return response.data; // You might want to return the full response data
    } catch (error) {
      const message =
        error.response?.data?.message || "An error occurred during the update";
      return rejectWithValue(message);
    }
  }
);

const OperationAnalystSlice = createSlice({
  name: "operationAnalyst",
  initialState,
  reducers: {
    /** reset update execution status api */
    resetUpdateExecutionStatusApi: (state) => {
      state.updateExecutionStatusApi = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSitesStatuses.pending, (state) => {
        state.getSiteStatusesApi = {
          loading: true,
        };
      })
      .addCase(getSitesStatuses.fulfilled, (state, action) => {
        state.getSiteStatusesApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(getSitesStatuses.rejected, (state, action) => {
        state.getSiteStatusesApi = {
          loading: false,
          error: action.payload,
        };
      })
      .addCase(updateExecutionStatus.pending, (state) => {
        state.updateExecutionStatusApi = {
          loading: true,
        };
      })
      .addCase(updateExecutionStatus.fulfilled, (state, action) => {
        state.updateExecutionStatusApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(updateExecutionStatus.rejected, (state, action) => {
        state.updateExecutionStatusApi = {
          loading: false,
          error: action.payload,
        };
      });
  },
});

export const { resetUpdateExecutionStatusApi } = OperationAnalystSlice.actions;

export default OperationAnalystSlice.reducer;
