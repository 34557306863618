import React, { useEffect } from "react";
import "./consolidatedStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsolidatedSummary } from "../../../../../features/PlanSummary/fetchconsolidatedSummarySlice";

import { useParams } from "react-router-dom";
import ConsolidatedSummary from "./ConsolidatedSummary";

export default function ConsolidatedTabContent({plan1 , plan2}) {
  const dispatch = useDispatch();
  //
  const { plan_id } = useParams();
  
  // Check if plan1 and plan2 are valid objects (not undefined or empty)
  const hasValidPlans =
    plan1 &&
    plan2 &&
    Object.keys(plan1).length > 0 &&
    Object.keys(plan2).length > 0;

  return (
    <div className="consolidated-tab-container">
      {hasValidPlans ? (
        <ConsolidatedSummary
          title1="Consolidated Summary (Conventional + Digital)"
          title2="Consolidated Summary (Conventional + Digital) Medium Wise"
          summaryData={plan1}
          mediumData={plan2}
        />
      ) : (
        <div>No data available.</div>
      )}
    </div>
  );
}
