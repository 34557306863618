// src/productsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../httpService/httpService";

export const clientMangement = createAsyncThunk(
  "clients/clientMangement",
  async ({ limit = 10, page = 1, search }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get("/admin/fetch-all-clients", {
        params: {
          limit,
          page,
          search,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch clients");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching clients"
      );
    }
  }
);

// Async thunk for getting specific user data
export const getSpecificUser = createAsyncThunk(
  "allSites/getSpecificUser",
  async (userId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.get(
        `/admin/fetch-single-client/profile/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch clients");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching clients"
      );
    }
  }
);
// Get singleUser 
export const getSingleUser = createAsyncThunk(
  "allSites/getSingleUser",
  async (userId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");

      const response = await axiosInstance.get(
        `/user/get-single-user/details/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch Users");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching users"
      );
    }
  }
);
const clientMangementSlice = createSlice({
  name: "clients",
  initialState: {
    items: [],
    status: "idle",
    error: null,
    loading: false,
    getSpecificUserApi: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clientMangement.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(clientMangement.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
        state.loading = false;
      })
      .addCase(clientMangement.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })
      /** get specific user*/
      
      .addCase(getSpecificUser.pending, (state) => {
        state.getSpecificUserApi = {
          loading: true,
        };
      })
      .addCase(getSpecificUser.fulfilled, (state, action) => {
        state.getSpecificUserApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(getSpecificUser.rejected, (state, action) => {
        state.getSpecificUserApi = {
          loading: false,
          error: action.payload,
        };
      })
      .addCase(getSingleUser.pending, (state) => {
        state.getSpecificUserApi = {
          loading: true,
        };
      })
      .addCase(getSingleUser.fulfilled, (state, action) => {
        state.getSpecificUserApi = {
          loading: false,
          response: action.payload,
        };
      })
      .addCase(getSingleUser.rejected, (state, action) => {
        state.getSpecificUserApi = {
          loading: false,
          error: action.payload,
        };
      });
  },
});

export default clientMangementSlice.reducer;
